body,
h1,
p {
  margin: 0;
  padding: 0;
}

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: #0F0A02;
}


button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

th {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  font-weight: 500;
  /* color: #AEAAAE; */
  /* color: #FEF8F4; */
  color: #FDF0E6;
  /* padding: 9px; */
}

.style-unsetter {
  background-color: unset !important;
  background-color: #0F0A02;
  padding: unset !important;
  margin: unset !important;
  background: unset !important;
  background: #0F0A02;
  line-height: unset !important;
}

thead {
  cursor: default;
  user-select: none;
}


tr {
  min-width: 1000px !important;
  min-height: 70px !important;
  box-shadow: inset 0 -1px 0 0px rgb(53, 50, 53);
  vertical-align: middle !important;
  padding: 2px;
}

td {
  min-height: 62px !important;
  vertical-align: middle !important;
  padding: 0px 14px 0px 0px !important;
}


.ais-Pagination-link {
  color: #FFFFFF;
  display: flex;
  background-image: unset !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #0F0A02;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, .05);
  cursor: pointer;
  font-size: .875rem;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.25rem;
  place-items: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

span.ais-Pagination-link {
  color: #FFFFFF !important;
  padding: 6px 16px;
}

.ais-Highlight-highlighted {
  background-color: black;
  color: #5468ff;
}


.results-container {
  margin-left: 32px;
}

.result-name {
  font-family: Inter, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #FFFFFF !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-size {
  font-family: Inter, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #6C6C6C !important;
}

.result-description {
  font-family: Inter, sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  width: 582px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-link {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  /* color: #D9781E; */
  color: #FF8C00;
  min-width: 100px !important;
  max-width: 100px !important;
}

.social-link:hover {
  color: #CC7000;
}

.join-button {
  user-select: none;
  font-family: Inter, sans-serif;
  font-size: 17px;
  font-weight: 500;
  border: 1px solid #FF8C00;
  color: #FF8C00;
  /* border: 1px solid #D9781E;
    color: #D9781E; */
  width: 102px;
  height: 46px;
  margin-top: 12px;
  margin-bottom: 12px;
  transition: background-color 0.6s ease-in-out, color 0.6s ease-in-out;
}

.join-button:hover {
  background-color: orange;
  border-color: darkorange;
  color: #0F0A02;
}

.search-input-container {
  position: relative;

  .search-icon {
    color: #FFFFFF;
    position: absolute;
    bottom: 14px;
    left: 12px;
    z-index: 9999;
  }

  .ais-SearchBox {
    .ais-SearchBox-form::before {
      background: unset !important;
    }
  }
}


.header {
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;

  min-width: 550px;
  max-width: 750px;
  min-height: 49px;
  max-height: 49px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 1px #FEF8F4, 0 0 2px #FEF8F4;
  }

  50% {
    box-shadow: 0 0 2px #FEF8F4, 0 0 3px #FEF8F4;
  }

  100% {
    box-shadow: 0 0 1px #FEF8F4, 0 0 2px #FEF8F4;
  }
}

.brand {
  padding-top: 10px;
  cursor: default;
  user-select: none;
  max-width: 198px;
  margin-right: 5px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 22px;
  max-height: 30px;
  height: 30px;
  color: #FF7F00;
  background-color: #0F0A02;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seo-text {
  max-width: 750px;
}

.h2-info {
  cursor: default;
  user-select: none;
  margin-right: 5px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 17px;
  max-height: 30px;
  height: 30px;
  color: #FFFFFF;
  background-color: #0F0A02;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-info {
  cursor: default;
  user-select: none;
  margin-right: 32px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #0F0A02;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
}


.input-empty {
  display: none;
}


.input-value {

  position: absolute;
  z-index: 3;
  right: 10px;
  background-color: #0F0A02;
  border: 1px solid #353235;
  background: #0F0A02;
  padding: 16px;
  margin-top: 5px;
  display: flex;
  text-align: left;
  max-width: 600px;
  box-shadow: 0 16px 24px -8px rgb(0 0 0 / 24%);

}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #353235;
}

.algolia-result-style {
  list-style: none;
  margin-left: 0;

  li {
    &:hover {
      background-color: #353235;
    }

    & .arrow-icon {
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
      height: .8rem;
    }

    & mark,
    .mark {
      padding: 0;
      background-color: rgb(255, 244, 190);
      font-weight: 600;
    }
  }
}

.search-title {
  font-weight: 600;
}

.ais-SearchBox-form {
  margin-bottom: 0px;
}

.ais-SearchBox-submit {
  width: 0.1px;
  height: 0.1px;
  font-size: 0.001px;
}

.ais-SearchBox-reset {
  width: 0.1px;
  height: 0.1px;
  font-size: 0.001px;
}

.searchbox {
  color: white;

  ::placeholder {
    color: white;
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }

}

.ais-Pagination-item--previousPage {
  display: none;
}

li.ais-Pagination-item.ais-Pagination-item--page.ais-Pagination-item--selected {
  background-image: unset !important;

  a {
    background-color: #FF8C00 !important;
    color: black;
  }
}

.ais-Pagination-item {
  background-image: unset !important;
  background-color: unset !important;
  color: black !important;
}

.ais-Pagination-list {
  padding-left: 0px;
}

.ais-Pagination-list a {
  padding: 6px 16px;
}

.ais-Pagination-item+.ais-Pagination-item>.ais-Pagination-link {
  border-radius: 0px;
  margin-left: -1px;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  font-weight: 700;
  font-family: Inter, sans-serif;
  background-color: rgb(255, 140, 0) !important;
  color: black;
}

.ais-Pagination-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0px;
  max-width: 1000px;
  box-sizing: border-box;

  a {
    padding: 8px 16px;
    color: white;

    &:hover,
    &:active {
      background-color: #353235;
      border: none;
      text-decoration: none;
    }
  }

  span.ais-Pagination-link {
    color: #FFFFFF !important;
    padding: 8px 16px;
  }

  .ais-Pagination-link {
    color: #FFFFFF !important;
    padding: 8px 16px;
  }

  .ais-Pagination-item--firstPage {
    display: none;
  }

  .ais-Pagination-link[aria-label="Previous page"] {
    padding-left: 0px !important;
  }

  .ais-Pagination-item--previousPage>.ais-Pagination-link,
  .ais-Pagination-item--nextPage>.ais-Pagination-link {
    padding: 8px 16px;
    color: orange;
    font-weight: 600;
  }
}




input[type="search"] {
  border-radius: unset !important;
  caret-color: #FF7F00;

  background-color: #0F0A02;
  border: 1px solid #ffffff;
  transition: border-color .35s ease-in-out, box-shadow .35s ease-in-out;
  font-size: 17px;
  line-height: 17px;
  padding-left: 40px;
  min-height: 49px;
  margin: 0;
  min-width: 366px;
  max-width: 366px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  color: white;

  &:focus {
    outline: none;
    border: 1.25px solid #FF7F00;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #353235;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #353235;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #353235;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #353235;
  }
}

.ais-ToggleRefinement-checkbox+.ais-ToggleRefinement-labelText {
  font-weight: 500;
  color: #6C6C6C;
}

.ais-ToggleRefinement-checkbox:checked+.ais-ToggleRefinement-labelText {
  color: #FFFFFF;
}

.ais-ToggleRefinement-checkbox {
  display: none;
}

.ais-ToggleRefinement-checkbox:checked {
  display: none;
}

.filter-bar {
  width: fit-content;
}

.ais-ToggleRefinement-label {
  display: flex;
  color: rgb(108, 108, 108);
  flex-direction: row;
  margin-left: 32px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-loadingIndicator {
  display: none;
}

.ais-SearchBox-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

@media only screen and (max-width: 600px) {

  body,
  h1,
  p {
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    width: 100vw;
    min-width: 0px;
    padding-bottom: 0px;

    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;

    max-height: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .brand {
    margin-top: 14px;
    margin-bottom: 14px;
    font-size: 28px;
    color: #FF7F00;
    min-height: 40px;
    max-width: 100%;
    line-height: 4px;
    padding-top: 16px;
    padding-bottom: 16px;
    user-select: none;
    font-family: Inter, sans-serif;
    font-weight: 600;
    background-color: #0F0A02;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  input[type="search"] {
    max-width: 100%;
    min-height: 60px;
    max-height: 80px;
  }


  .search-input-container {
    max-width: 100%;
    position: relative;

    .search-icon {
      position: absolute;
      bottom: 20px;
      left: 14px;
      z-index: 9999;
      height: 20px;
      width: 20px;
    }
  }

  .seo-text {
    margin-left: 16px;
    margin-right: 32px;
  }

  .h2-info {
    cursor: default;
    user-select: none;
    font-family: Inter, sans-serif;
    font-weight: 500;
    margin-top: 28px;
    margin-bottom: 32px;
    font-size: 24px;
    max-height: 30px;
    color: #FFFFFF;
    background-color: #0F0A02;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-info {
    cursor: default;
    user-select: none;
    margin-left: 0px;
    margin-right: 5px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    background-color: #0F0A02;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ais-ToggleRefinement-checkbox+.ais-ToggleRefinement-labelText {
    color: #6C6C6C;
  }

  .ais-ToggleRefinement-checkbox:checked+.ais-ToggleRefinement-labelText {
    color: #FFFFFF;
  }

  .ais-ToggleRefinement-checkbox {
    display: none;
  }

  .ais-ToggleRefinement-checkbox:checked {
    display: none;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .ais-ToggleRefinement-label {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-top: 8px;
  }


  .results-container {
    margin-left: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-result-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  .social-links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .result-description {
    max-width: 90vw !important;
    margin-left: 16px;
    margin-right: 16px;
    height: 60px;
  }

  .result-name {
    font-family: Inter, sans-serif !important;
    font-size: 20px !important;
    font-weight: medium !important;
    color: #FFFFFF !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    text-align: center;
  }
}


label {
  display: flex;
  margin: 0;
}

/* Search Icon in .navbar-v6*/
svg.nav-search__icon {
  position: absolute;
  bottom: auto;

  margin-left: 8px;

  :-webkit-direct-focus {
    outline-color: none;
    outline-style: auto;
    outline-width: 0;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) and (-webkit-device-pixel-ratio: 3) {
  .search-icon {
    display: none;
  }
}